import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import resumePdf from "../static/Justin Park Resume.pdf";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const Resume = ({ bg }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div
      className={`bg-cover bg-center h-full ${
        bg === "" ? "bg-gradient-to-r from-green-400 to-blue-500" : ""
      }`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="max-h-screen">
        <div className="pt-14 max-w-3xl h-screen mx-auto">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
            <Viewer
              fileUrl={resumePdf}
              plugins={[defaultLayoutPluginInstance]}
            />
          </Worker>
        </div>
      </div>
    </div>
  );
};

export default Resume;
