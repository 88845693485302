import React from "react";

const Contact = ({ bg = "" }) => {
  return (
    <div
      className={`bg-cover bg-center h-full ${
        bg === "" ? "bg-gradient-to-r from-green-400 to-blue-500" : ""
      }`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="min-h-full flex flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-4 shadow sm:rounded-lg sm:px-10 py-10 flex flex-col justify-center gap-8">
            <div className="flex flex-col text-center gap-2 text-2xl font-serif">
              <p>Justin Park</p>
              <p>JustinParkCS@gmail.com</p>
            </div>
            <div className="flex flex-row justify-center gap-x-8">
              <a
                href="https://www.linkedin.com/in/justinparkcs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-16"
                  src="https://img.icons8.com/fluency/48/000000/linkedin.png"
                  alt="linkedin"
                />
              </a>
              <a
                href="https://github.com/JustinParkCS"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://img.icons8.com/glyph-neue/64/000000/github.png"
                  alt="github"
                />
              </a>
            </div>
            <div className="text-sm">
              <p>
                Hello~ Justin here. My pronouns are He/Him/His. Currently a
                student pursuing his bachelor's of Science Degree at City
                College of New York, (Grove School of Engineering) due to
                graduate June 2022.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
