import React from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing";
import Projects from "./pages/Projects";
import Resume from "./pages/Resume";
import Experiences from "./pages/Experiences";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";

const App = () => {
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const getData = async () => {
    await axios
      .get("https://jp-h-portfolio.herokuapp.com/api/portfolio/get/hireme/")
      .then((res) => {
        setData(res.data[0]);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    getData();
  }, []);
  React.useEffect(() => {}, [loading]);
  return (
    <div className="h-screen w-screen relative">
      {loading && (
        <Router>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Landing bg={data.bg_img.home} />} />
            <Route
              exact
              path="/resume"
              element={<Resume bg={data.bg_img.resume} />}
            />
            <Route
              exact
              path="/contact"
              element={<Contact bg={data.bg_img.contact} />}
            />
            <Route
              exact
              path="/projects"
              element={
                <Projects bg={data.bg_img.projects} data={data.projects} />
              }
            />
            <Route
              exact
              path="/experiences"
              element={
                <Experiences
                  bg={data.bg_img.experiences}
                  data={data.experiences}
                />
              }
            />
          </Routes>
        </Router>
      )}
    </div>
  );
};

export default App;
