import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [selected, setSelected] = useState("Home");

  const HandleSelected = (item) => {
    setSelected(item);
  };

  return (
    <nav className="absolute top-0 h-20 bg-transparent items-center px-4 w-full pt-2">
      <div
        className={`flex flex-row justify-between ${
          selected === "Contact" ? "text-black" : "text-white"
        }`}
      >
        <div>
          <Link to="/">
            <button
              type="button"
              className="btn btn-light text-3xl font-bold py-2 bg-white text-black w-12 h-12 rounded-full font-serif"
              onClick={(e) => HandleSelected("Home")}
            >
              JP
            </button>
          </Link>
        </div>
        <div>
          <Link to="/">
            <button
              type="button"
              className="btn btn-light text-xl font-bold py-2 px-4"
              onClick={(e) => HandleSelected("Home")}
            >
              Home
            </button>
          </Link>
          <Link to="/resume">
            <button
              type="button"
              className="btn btn-light text-xl font-bold py-2 px-4"
              onClick={(e) => HandleSelected("Resume")}
            >
              Resume
            </button>
          </Link>
          <Link to="/projects">
            <button
              type="button"
              className="btn btn-light text-xl font-bold py-2 px-4"
              onClick={(e) => HandleSelected("Projects")}
            >
              Projects
            </button>
          </Link>
          <Link to="/experiences">
            <button
              type="button"
              className="btn btn-light text-xl font-bold py-2 px-4"
              onClick={(e) => HandleSelected("Experiences")}
            >
              Experiences
            </button>
          </Link>
        </div>
        <div>
          <Link to="/contact">
            <button
              type="button"
              className="btn btn-light text-xl font-bold py-2 px-4"
              onClick={(e) => HandleSelected("Contact")}
            >
              Contact
            </button>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
