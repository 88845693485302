import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const CardView = (data) => {
  const [cardData, setCardData] = React.useState(data.data);
  React.useEffect(() => {
    setCardData(data.data);
  }, [data]);

  return (
    <div className="py-10 px-20 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {cardData.length > 0 &&
        Object.values(cardData).map((item, index) => (
          <article
            key={`${item.title}-${index}`}
            className="relative rounded leading-snug bg-white block justify-center"
          >
            <Card className="max-w-44 grow">
              <div className="flex justify-center">
                <img
                  component="img"
                  className="object-contain p-4 h-80 w-auto"
                  src={item.image}
                  alt="data_img"
                />
              </div>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {item.company ? (
                    <div>
                      <p className="font-bold text-3xl">{item.company}</p>
                      <p>{item.title}</p>
                      <p className="text-lg">{`${item.dates[0]} - ${item.dates[1]}`}</p>
                    </div>
                  ) : (
                    <div>
                      <p className="font-bold text-3xl">{item.title}</p>
                      <p className="text-sm">({item.tech_stack})</p>
                    </div>
                  )}
                </Typography>
                <p className="pt-4 h-52 text-gray-600">{item.description}</p>
              </CardContent>
              <CardActions className="pb-4">
                <Button
                  size="medium"
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className=""
                >
                  {item.tech_stack ? "Github Link" : "Company Link"}
                </Button>
              </CardActions>
            </Card>
          </article>
        ))}
    </div>
  );
};

export default CardView;
