import React from "react";

const Landing = ({ bg }) => {
  return (
    <div
      className={`bg-cover bg-center h-full ${
        bg === "" ? "bg-gradient-to-r from-green-400 to-blue-500" : ""
      }`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="w-full h-auto">
        <div className="h-screen flex flex-col justify-center text-center gap-y-4 font-serif text-white">
          <p className="text-8xl">You've come to the right place!</p>
          <p className="text-6xl">Feel free to browse around.</p>
        </div>
      </div>
    </div>
  );
};

export default Landing;
