import React from "react";
import CardView from "../components/CardView";

const Projects = ({ bg, data }) => {
  return (
    <div
      className={`bg-cover bg-center ${
        bg === "" ? "bg-gradient-to-r from-green-400 to-blue-500" : ""
      }`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="pt-10">
        <CardView data={data} />;
      </div>
    </div>
  );
};

export default Projects;
